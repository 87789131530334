const introModel = {
  header:"Glasgow City Chambers",
  subheader:"A digital learning resource designed to enable school children to explore architecture, design and social heritage.",
  didYouKnow:[
    "Everyone can visit the City Chambers. It is open to the public via free daily tours, so you can see for yourself where the big decisions are made for Glasgow.",
    "Before the City Chambers was opened the council met at the Tolbooth on the corner of High Street and Trongate. The steeple of the Tolbooth is all that remains today.",
    "The City Chambers opened in 1889 with a 10 day public viewing which attracted 400,000 people.",
    "The City Chambers building and decoration cost £578,232 in 1888 which would be £96,156,095.06 today!",
    "Stone moulding machinery was used for the first time in Glasgow during the building of the City Chambers, which meant that repeat elements of the building, such as columns or plinths, could be mass produced.",
    "10 million bricks were used in the building of the City Chambers.",
    "Àrd-Phrobhaist is the Gaelic word for Lord Provost.",
    "Many sculptures at the City Chambers were carved by John Mossman (1817-90), who was one of the founders of the Glasgow School of Art.",
    "The gilding, which is gold detailing on decorations, in the City Chambers isn’t just gold coloured paint - it’s real gold leaf!",
    "Glasgow City Chambers is a category A listed building, this means that it is a building of national or international importance.",
    "The City Chambers’ foundation stone was laid on the 6th October 1883, the topmost stone was laid on 22nd August 1888 meaning it took nearly 5 years to build.",
    "There is a plaque of measurement standards on the front of the City Chambers; Victorian merchants and traders would use these standards to accurately and fairly measure goods.",
    "Before the City Chambers was lit by electricity in 1888 only three other public buildings were electrically lit: St Enoch Railway Station and the Head Post Office of Glasgow in 1879, and Queen Street Railway Station in 1880.",
    "William Young’s original architectural drawings of the City Chambers can be viewed at the Mitchell Library in their Special Collections department.",
    "The City Chambers building works continued and an extension was added in 1912 designed by architects Watson and Salmond.",
    "The central figure of Truth on the George Square pediment is popularly known as Glasgow's Statue of Liberty because of its similarity to New York's Statue of Liberty.",
  ]
}

export default introModel