import { useEffect, useRef, useState } from "react"
import { 
  motion,
  useScroll,
  useMotionValueEvent,
  AnimatePresence
} from "framer-motion"

import Timeline from "./models/TimelineModel"
import IndexDB from "./lib/IndexDB"
import SectionWrapper from "./components/SectionWrapper"
import BurgerMenu from "./components/BurgerMenu"
import LoadingScreen from "./components/LoadingScreen"
import OverlayModel from "./models/OverlayModel"
import Overlay from "./components/Overlay"
import { is } from "immutable"


const App = () => {

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  const ref = useRef(null)

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end','end end']
  })

  const [yPos, setYPos] = useState(0)

  useMotionValueEvent(
    scrollYProgress,
    'change',
    (latest) => {
      setYPos(latest)
    }
  )

  const [dbStatus, setDBStatus] = useState('initialising')
  const [sections, setSections] = useState()

  const initialiseDB = async () => {
    await IndexDB.init(sections)
    await IndexDB.checkStorage()
    setDBStatus('initialised')
  }

  const [loadingIntro, setLoadingIntro] = useState(true)

  const fetchImage = async (section,id) => {
    //check if image exists in database
    const name = `${section}-${id.toString().padStart(4, '0')}`
    const image = await IndexDB.getImage(section,name)
    if(!image) {
      //fetch image as blob
      console.log(`Fetching image ${id} for ${section}`)
      const response = await fetch(currentFrame(section,id))
      //const blob = await response.blob()
      //get array buffer
      const arrayBuffer = await response.arrayBuffer()
      const data = {
        id: name,
        buffer: arrayBuffer
      }

      //add image to database
      await IndexDB.addImage(section,data)

    } else {
      console.log(`Image ${id} already exists`)
    }
    if(id === Timeline[section].frameCount) {
      console.log(`All images for ${section} downloaded`)
      if(section === 'intro') {
        setLoadingIntro(false)
      }
      //increment section index
      //get length of sections
      const length = sections.length
      //check if last section
      if(sectionToDownloadIndex === length - 1) {
        //all sections downloaded
        console.log('all sections downloaded')
        return
      } else {
        //increment section index
        setSectionToDownloadIndex(sectionToDownloadIndex + 1)
      }
    }
  }

  const preloadImages = async (section,frameCount) => {
    //create syncronous loop
    const preload = async () => {
      for (let i = 1; i <= Timeline[section].frameCount; i++) {
        try {
          await fetchImage(section,i)
        }
        catch (error) {
          console.log(error)
        }
      }
    }
    preload()
  }

  const loadSectionImages = async (section) => {
    if(!IndexDB.db) return
    //check if all images for section are downloaded
    const transaction = IndexDB.db.transaction([section], 'readonly')
    const store = transaction.objectStore(section)
    const request = store.count()
    request.onerror = (event) => {
      console.log('Error loading image',request.error)
    }
    request.onsuccess = async (event) => {
      console.log('All frames loaded',event.target.result)
      //set frames
      if(request.result === Timeline[section].frameCount) {
        //frames are ready
        console.log('frames ready')
        //IF INTRO SECTION
        if(section === 'intro') {
          setLoadingIntro(false)
        }
        //get length of sections
        const length = sections.length
        //check if last section
        if(sectionToDownloadIndex === length - 1) {
          //all sections downloaded
          console.log('all sections downloaded')
          return
        } else {
          //increment section index
          setSectionToDownloadIndex(sectionToDownloadIndex + 1)
        }
      } else {
        //frames are not ready
        console.log('frames not ready')
        await preloadImages(section,Timeline[section].frameCount)
      }
    }
  }

  const [sectionToDownloadIndex, setSectionToDownloadIndex] = useState(0)

  const loadIntro = async () => {
    //load images per sequence synchronously
    //for (let i = 0; i < sections.length; i++) {
    await loadSectionImages(sections[sectionToDownloadIndex],Timeline[sections[sectionToDownloadIndex]].frameCount)
    //}
  }

  useEffect(() => {
    if(!sections) return
    loadSectionImages(sections[sectionToDownloadIndex],Timeline[sections[sectionToDownloadIndex]].frameCount)
  }, [sectionToDownloadIndex])
    

  useEffect(() => {
    if(dbStatus !== 'initialised') return
    console.log('loop sections for preload',dbStatus)
    //loadIntro()
  }, [dbStatus])
  
  const [currSection, setCurrSection] = useState('intro')

  useEffect(() => {

     //loop through timeline and use keys for nav
    const keys = Object.keys(Timeline)
    setSections(keys)

    const nav = keys.map((key) => {
      return {
        name: key,
        header: Timeline[key].header,
        colour: Timeline[key].colour,
        frameCount: Timeline[key].frameCount,
        closed: true
      }
    })
    setSectionNav(nav)
    
    const handleResize = () => {
      setViewportWidth(window.innerWidth)
      setViewportHeight(window.innerHeight)
      setInnerWidth(window.innerWidth)
      setInnerHeight(window.innerHeight)
    }

    //setTimeout(() => {
    //  setLoadingIntro(false)
    //}, 1000)
    cacheIntroImagesWindow()

    window.addEventListener("resize", handleResize)
  
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  },[])

  const [introImagesLoaded, setIntroImagesLoaded] = useState(0)

  const currentFrame = index => (
    `https://d1qk8s4yjmvpvz.cloudfront.net/ourcitychambers/animations/intro/${index.toString().padStart(4, '0')}.jpg`
  )

  const setImageInWindow = (index) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = currentFrame(index)
      img.onload = () => {
        window[currentFrame(index)] = img
        setIntroImagesLoaded(prevState => prevState + 1)
        resolve()
      }
      img.onerror = () => {
        reject()
      }
    })
  }

  const cacheIntroImagesWindow = async() => {
    //promise all images
    const promises = []
    for (let i = 1; i < 1525; i++) {
      promises.push(setImageInWindow(i))
    }
    Promise.all(promises)
      .then(() => {
        console.log('All intro images cached in window')
      })
      .catch(() => {
        console.log('Error caching images in window')
      })
  }

  const [sectionIndex, setSectionIndex] = useState(0)
  const [currFrame, setCurrFrame] = useState(1)
  const [timeline, setTimeline] = useState(null)

  useEffect(() => {
    if(!currSection) return
    setTimeline(Timeline[currSection.name])
  }, [currSection])

  const [sectionNav, setSectionNav] = useState(false)

  useEffect(() => {
    setCurrSection(sectionNav[sectionIndex])
  }, [sectionNav])


  const [yOffset, setYOffset] = useState(0)
  const [xOffset, setXOffset] = useState(0)

  const [sectionsClosed, setSectionsClosed] = useState('open')

  const nextSection = () => {
    console.log('next section')
    //increment section index, use setstate callback to set section§
    //check length of sections and return if index is out of bounds
    const length = sections.length
    if(sectionIndex >= length - 1){
      console.log('last section')
      _openOverlay({
        header: 'Credits',
        overlay: 'credits'
      })
      return
    }

    setSectionIndex(sectionIndex + 1)
    const section = sectionNav[sectionIndex + 1]
    selectSection(section)
  }


  useEffect(() => {
    if(!timeline) return
    _setScrollHeight()
  }, [timeline])

  const previousSection = () => {
    console.log('previous section')
  }

  const [isIntro, setIsIntro] = useState(true)
  const [scrollHeight, setScrollHeight] = useState('100vh')

  const selectSection = (section) => {
    setCurrSection(section)
    setSectionsClosed('open')
    setCurrFrame(1)
    setTimeline(Timeline[section.name])
    //destroy component and reload
    setScrollHeight('0.1vh')
    //setTimeout(() => {
      _setScrollHeight()
    //},1)
  }

  const selectSectionNav = (section) => {
    //get index of section
    const index = sectionNav.findIndex((item) => item.name === section.name)
    setSectionIndex(index)
    selectSection(section)
  }

  const _setScrollHeight = () => {
    if(_canvasLoading){
      setScrollHeight('100vh')
      return
    }
    const ratio = 2000 / 725
    const height = timeline.frameCount * ratio
    setScrollHeight(`${height}vh`)
  }

  const [_canvasLoading, _setCanvasLoading] = useState(true)

  useEffect(() => {
    console.log('canvas loading', _canvasLoading)
    if(_canvasLoading){
      setScrollHeight('100vh')
    } else {
      _setScrollHeight()
    }
  }, [_canvasLoading])

  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [overlay, setOverlay] = useState(null)


  const _openOverlay = (overlay) => {
    setOverlay(overlay)
    setIsOverlayOpen(true)
  }


  if(!timeline) return null

  if(loadingIntro){
    return (
      <>
        <LoadingScreen
          exitLoadingScreen={() => setLoadingIntro(false)}
          frames={1525}
          currFrame={introImagesLoaded}
          openOverlay={()=>_openOverlay({
            overlay: 'how_to'
          })}/>
        <AnimatePresence>
          {
            isOverlayOpen &&
            <motion.div
              key={`overlay`}
              className="overlay-container"
              initial={{
                opacity: 0,
                y: 100
              }}
              animate={{
                opacity: 1,
                y: 0
              }}
              exit={{
                opacity: 0,
                y: 100
              }}>
              <Overlay
                overlay={overlay}
                isOpen={isOverlayOpen}
                setIsOpen={(open) => setIsOverlayOpen(open)}
                closeOverlay={()=>setIsOverlayOpen(false)}/>
            </motion.div>
          }
        </AnimatePresence>
      </>
    )
  }

  return (
    <motion.div 
      style={{
        height: isOverlayOpen ? '100vh' : scrollHeight,
      }}
      key={`app-container`}
      className="app-container">
        <BurgerMenu
          sections={sectionNav}
          selectSection={(section) => selectSectionNav(section)}
          selectOverlay={(overlay)=>_openOverlay(overlay)}/>
        <motion.div
          key={`scroll-container`}>
            <SectionWrapper
              canvasLoading={(loading) => _setCanvasLoading(loading)}
              key={`section-wrapper-${currSection}`}
              db={IndexDB}
              dbStatus={dbStatus}
              currSection={currSection.name}
              innerWidth={innerWidth}
              innerHeight={innerHeight}
              viewportWidth={viewportWidth}
              viewportHeight={viewportHeight}
              yOffset={yOffset}
              xOffset={xOffset}
              totalFrames={timeline.frameCount}
              currFrame={currFrame}
              timeline={timeline}
              yPos={yPos}
              nextSection={()=>nextSection()}
              previousSection={()=>previousSection()}
              setXOffset={(x) => setXOffset(x)}
              setYOffset={(y) => setYOffset(y)}
              setCurrFrame={(frame) => setCurrFrame(frame)}
              setSectionsClosed={(closed) => setSectionsClosed('closed')}/>
        </motion.div>
        <AnimatePresence>
          {
            isOverlayOpen &&
            <motion.div
              key={`overlay`}
              className="overlay-container"
              initial={{
                opacity: 0,
                y: 100
              }}
              animate={{
                opacity: 1,
                y: 0
              }}
              exit={{
                opacity: 0,
                y: 100
              }}>
              <Overlay
                overlay={overlay}
                isOpen={isOverlayOpen}
                setIsOpen={(open) => setIsOverlayOpen(open)}
                closeOverlay={()=>setIsOverlayOpen(false)}/>
            </motion.div>
          }
        </AnimatePresence>
    </motion.div>
  )
}

export default App
