import {
  useEffect,
  useRef,
  useState
} from "react"

import {
  AnimatePresence,
  motion,
  useScroll,
} from "framer-motion"
import { set } from "immutable"

const wrapperVariants = {
  initial: {
    backgroundColor:'rgba(0,0,0,0)',
  },
  animate: {
    backgroundColor:'rgba(0,0,0,0.1)',
    transition: {
      duration: 0.5,
      delay: 0.8
    }
  },
  exit: {
    backgroundColor:'rgba(0,0,0,0)',
  }
}

const AnimationLayer = ({
  timeline,
  yPos,
  currFrame,
  totalFrames,
  innerWidth,
  innerHeight,
  viewportWidth,
  viewportHeight,
  nextSection,
  previousSection,
  isClosed,
  toggleOpen,
  canvasLoading
}) => {

  const ref = useRef(null)

  const [greenCoats, setGreenCoats] = useState(null)
  const [stops, setStops] = useState(null)
  const [textBoxes, setTextBoxes] = useState(null)

  useEffect(() => {
    //loop green coat and set
    const greenCoats = timeline.greenCoat.map((greenCoat, index) => {
      return {
        frame: greenCoat.frame,
        image: greenCoat.image,
        text: greenCoat.text
      }
    })
    setGreenCoats(greenCoats)
    //loop callouts and set
    const callouts = timeline.stops.map((stop, index) => {
      return {
        frame: stop.frame,
        callouts: stop.callouts
      }
    })
    setStops(callouts)

    if(timeline.textBoxes){
      const textBoxes = timeline.textBoxes.map((textBox, index) => {
        return {
          frame: textBox.frame,
          text: textBox.text
        }
      })
      setTextBoxes(textBoxes)
    }
    
  }, [])

  const [_canvasLoading, _setCanvasLoading] = useState(false)

  useEffect(() => {
    console.log('canvas loading', canvasLoading)
    _setCanvasLoading(canvasLoading)
  }, [canvasLoading])

  const [scrollY, setScrollY] = useState(0)
  const [toggleHeader, setToggleHeader] = useState(true)
  const [greenCoat, setGreenCoat] = useState(null)
  const [callouts, setCallouts] = useState(null)
  const [sectionEnd, setSectionEnd] = useState(false)
  const [scrollDownUI, setScrollDownUI] = useState(true)
  const [textBox, setTextBox] = useState(null)

  useEffect(() => {
    //toggle header after frame 5
    if(currFrame > 5){
      setScrollY(1)
      setToggleHeader(false)
      setScrollDownUI(false)
    } else {
      setScrollY(0)
      setToggleHeader(true)
      setScrollDownUI(true)
    }

    if(currFrame === totalFrames){
      setSectionEnd(true)
    } else {
      setSectionEnd(false)
    }

    //check if green coat is in frame
    if(greenCoats){
      const greenCoat = greenCoats.find((greenCoat) => {
        return currFrame >= greenCoat.frame[0] && currFrame <= greenCoat.frame[1]
      })
      if(greenCoat){
        setGreenCoat(greenCoat)
      } else {
        setGreenCoat(null)
      }
    }
    //check if callouts are in frame
    if(stops){
      //find all callouts in frame
      const foundCallouts = stops.filter((stop) => {
        if(currFrame >= stop.frame[0] && currFrame <= stop.frame[1]){
          return stop.callouts
        }
      })
      //reduce callouts to single array
      const mergedCallouts = foundCallouts.reduce((acc, curr) => {
        return [...acc, ...curr.callouts]
      }, [])

      if(mergedCallouts.length > 0){
        setCallouts(mergedCallouts)
      } else {
        setCallouts(null)
      }
    }

    //check if text boxes are in frame
    if(textBoxes){
      const textBox = textBoxes.find((textBox) => {
        return currFrame >= textBox.frame[0] && currFrame <= textBox.frame[1]
      }
      )
      if(textBox){
        setTextBox(textBox)
      } else {
        setTextBox(null)
      }
    }

  }, [currFrame])

  const calculateCalloutPosX = (calloutX,position) => {
    let posRefactor = 0
    switch(position){
      case 'top-left':
        posRefactor = -90
        break
      case 'bottom-left':
        posRefactor = -90
        break
      case 'bottom-right':
        posRefactor = -10
        break
      case 'top-right':
        posRefactor = -10
    }

    const imgRatio = 1080 / 1920
    const winRatio = innerHeight / innerWidth
    let xTranslated = calloutX
    if(imgRatio > winRatio){
      const h = innerWidth * imgRatio
      xTranslated = calloutX * (innerWidth / 1920)
    }
    if(imgRatio < winRatio){
      const w = innerWidth * winRatio / imgRatio
      const xOffset = (viewportWidth - w) / 2
      xTranslated = calloutX * (innerHeight / 1080) + xOffset
    }

    return xTranslated + posRefactor
  }

  const calculateCalloutPosY = (calloutY,position) => {
    let posRefactor = 0
    switch(position){
      case 'top-left':
        posRefactor = -90
        break
      case 'top-right':
        posRefactor = -90
        break
      case 'bottom-left':
        posRefactor = -10
        break
      case 'bottom-right':
        posRefactor = -10
    }

    const imgRatio = 1080 / 1920
    const winRatio = innerHeight / innerWidth
    let yTranslated = calloutY
    if(imgRatio > winRatio){
      const h = innerWidth * imgRatio
      const yOffset = (innerHeight - h) / 2
      yTranslated = calloutY * (innerWidth / 1920) + yOffset
    }
    if(imgRatio < winRatio){
      const w = innerWidth * winRatio / imgRatio
      yTranslated = calloutY * (innerHeight / 1080)
    }

    return yTranslated + posRefactor

  }

  const calculateCalloutCirclePosX = (position) => {
    
    switch(position){
      case 'top-left':
        return 87
      case 'bottom-left':
        return 87
      case 'top-right':
        return 13
      case 'bottom-right':
        return 13
    }
  }

  const calculateCalloutCirclePosY = (position) => {
    switch(position){
      case 'top-left':
        return 87
      case 'bottom-left':
        return 13
      case 'top-right':
        return 87
      case 'bottom-right':
        return 13
    }
  }

  const calculateCalloutLinePosXStart = (position) => {
    switch(position){
      case 'top-left':
        return 80
      case 'bottom-left':
        return 80
      case 'top-right':
        return 20
      case 'bottom-right':
        return 19
    }
  }

  const calculateCalloutLinePosYStart = (position) => {
    switch(position){
      case 'top-left':
        return 80
      case 'bottom-left':
        return 19
      case 'top-right':
        return 80
      case 'bottom-right':
        return 19
    }
  }

  const calculateCalloutLinePosXEnd = (position) => {
    switch(position){
      case 'top-left':
        return 0
      case 'bottom-left':
        return 0
      case 'top-right':
        return 100
      case 'bottom-right':
        return 100
    }
  }

  const calculateCalloutLinePosYEnd = (position) => {
    switch(position){
      case 'top-left':
        return 0
      case 'bottom-left':
        return 100
      case 'top-right':
        return 0
      case 'bottom-right':
        return 100
    }
  }

  if(isClosed){
    return (
      <motion.div 
        key={`animation-layer`}
        className="animation-layer">
        <motion.h1
          onClick={() =>toggleOpen()}
          key={`header-${timeline.header}`}
          style={{
            backgroundColor:timeline.colour
          }}
          className="animation-layer-header">
            {timeline.header}
        </motion.h1>
      </motion.div>
    )
  }


  return (
      <motion.div 
        variants={wrapperVariants}
        initial="initial"
        animate={callouts ? "animate" : "initial"}
        exit="exit"
        key={`animation-layer`}
        className="animation-layer">
        <AnimatePresence>
          {
            toggleHeader &&
            <motion.h1
              key={`header-${timeline.header}`}
              initial={{
                opacity:0
              }}
              animate={{
                opacity:1,
                transition:{
                  duration: 0.5,
                  delay: 0.5
                }
              }}
              exit={{
                y:-50,
                opacity:0
              }}
              style={{
                backgroundColor:timeline.colour
              }}
              className="animation-layer-header">
                {timeline.header}
            </motion.h1>
          }
        </AnimatePresence>
        <AnimatePresence>
          {
            toggleHeader &&
            <motion.div
              key={`text-${timeline.subheader}`}
              initial={{
                y:-50,
                opacity:0
              }}
              animate={{
                y:0,
                opacity:1,
                transition:{
                  duration: 0.5,
                  delay: 0.5
                }
              }}
              exit={{
                y:-50,
                opacity:0
              }}
              transition={{
                opacity: {
                  duration: 0.5
                }
              }}
              style={{
                borderColor:timeline.colour
              }}
              className="animation-text">
              <div 
                className="animation-layer-subheader">
                {timeline.subheader}
              </div>
              <div
                className="animation-layer-description">
                {timeline.description}
              </div>
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence>
          <motion.div 
            key={`shutter-left`}
            animate={{
              opacity:1,
              transform: `translateX(${scrollY * -100}%)`
            }}
            transition={{
              duration: 0.8,
              delay: 0.5
            }}
            initial={{
              opacity:0
            }}
            className="shutter-left">
          </motion.div>
          <motion.div
            key={`shutter-right`}
            animate={{
              opacity:1,
              transform: `translateX(${scrollY * 100}%)`
            }}
            transition={{
              duration: 0.8,
              delay: 0.5
            }}
            initial={{
              opacity:0
            }} 
            className="shutter-right">
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          {
            sectionEnd &&
            <motion.div
              key={`shutter-close-wrapper`}>
              <motion.div 
                key={`shutter-close-left`}
                initial={{
                  transform: `translateX(-100%)`
                }}
                animate={{
                  transform: `translateX(calc(0% + 1px))`
                }}
                exit={{
                  transform: `translateX(-100%)`,
                }}
                transition={{
                  duration: 0.8
                }}
                onAnimationComplete={(definition) => {
                  //nasty hack to get around state not updating in time for the removal of the shutter
                  if(definition.transform === 'translateX(calc(0% + 1px))'){
                    nextSection()
                  }
                }}
                className="shutter-close-left">
              </motion.div>
              <motion.div
                key={`shutter-close-right`}
                initial={{
                  transform: `translateX(100%)`
                }}
                animate={{
                  transform: `translateX(0%)`
                }}
                exit={{
                  transform: `translateX(100%)`
                }}
                transition={{
                  duration: 0.8
                }}
                className="shutter-close-right">
              </motion.div>
            </motion.div>
          }
        </AnimatePresence>
        
        <AnimatePresence>
          {
            greenCoat &&
            <motion.div
              key={`green-coat`}
              initial={{
                transform: `translateY(100%)`,
                opacity:0
              }}
              animate={{
                transform: `translateY(0%)`,
                opacity:1
              }}
              exit={{
                opacity:0
              }}
              transition={{ 
                type: "spring",
                stiffness: 100,
                damping: 20,
                duration: 0.5
              }}
              className="green-coat-wrapper">
                <AnimatePresence>
                  <motion.div
                    initial={{
                      transform: `translateY(100%)`,
                      opacity:0
                    }}
                    animate={{
                      transform: `translateY(0%)`,
                      opacity:1
                    }}
                    exit={{
                      opacity:0
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 100,
                      damping: 20,
                      duration: 0.5,
                      delay: 0.5
                    }}
                    key={`green-coat-text`}
                    className="bubble bubble-bottom-left">
                    {greenCoat.text}
                  </motion.div>
                </AnimatePresence>
              <motion.img 
                key={`green-coat-image`}
                src={greenCoat.image} className="green-coat"/>
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            key={`callouts-container`}
            className="callouts-container">
            {
              callouts &&
              callouts.map((callout, index) => {
                return (
                    <motion.div
                      initial={{
                        transform: `translateY(100%)`,
                        opacity:0
                      }}
                      animate={{
                        transform: `translateY(0%)`,
                        opacity:1
                      }}
                      exit={{
                        transform: `translateY(100%)`,
                        opacity:0
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 100,
                        damping: 20,
                        duration: 0.5
                      }}
                      key={`callout-${index}`}
                      style={{
                        left: `${calculateCalloutPosX(callout.x,callout.position)}px`,
                        top: `${calculateCalloutPosY(callout.y,callout.position)}px`
                      }}
                      className="callout-wrapper">
                        <motion.svg
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none">
                          <motion.circle
                            cx={calculateCalloutCirclePosX(callout.position)}
                            cy={calculateCalloutCirclePosY(callout.position)}
                            r="10"
                            stroke="#fff"
                            strokeWidth="3"
                            fill="rgba(0,0,0,0.3)"
                            initial={{
                              pathLength: 0
                            }}
                            animate={{
                              pathLength: 1
                            }}
                            transition={{
                              pathLength: {
                                duration: 0.5,
                                ease: "easeInOut"
                              }
                            }}
                            />
                            <motion.line 
                              x1={calculateCalloutLinePosXStart(callout.position)}
                              y1={calculateCalloutLinePosYStart(callout.position)}
                              x2={calculateCalloutLinePosXEnd(callout.position)}
                              y2={calculateCalloutLinePosYEnd(callout.position)}
                              stroke="#fff"
                              strokeWidth="3"
                              initial={{
                                pathLength: 0
                              }}
                              animate={{
                                pathLength: 1
                              }}
                              transition={{
                                pathLength: {
                                  duration: 0.5,
                                  ease: "easeInOut"
                                }
                              }}
                              />
                        </motion.svg>
                        <motion.div 
                          animate={{
                            opacity:1
                          }}
                          initial={{
                            opacity:0
                          }}
                          exit={{
                            opacity:0
                          }}
                          transition={{
                            duration: 0.5,
                            delay: 0.5
                          }}
                          //create a ref for this element to position it
                          ref={(element) => {
                            if(element){
                              //get elemwent width
                              const width = element.offsetWidth
                              //change left position based on width
                              switch(callout.position){
                                case 'top-left':
                                  element.style.left = `-${width -1}px`
                                  break
                                case 'bottom-left':
                                  element.style.left = `-${width -1}px`
                                  break
                              }
                            }
                          }}
                          key={`callout-${index}`}
                          className={`callout-title ${callout.position}`}>
                          {callout.title}
                        </motion.div>
                      <motion.div 
                        animate={{
                          opacity:1
                        }}
                        initial={{
                          opacity:0
                        }}
                        exit={{
                          opacity:0
                        }}
                        transition={{
                          duration: 0.5,
                          delay: 0.5
                        }}
                        ref={(element) => {
                          if(element){
                            //get elemwent width
                            const width = element.offsetWidth
                            //change left position based on width
                            switch(callout.position){
                              case 'top-left':
                                element.style.left = `-${width}px`
                                break
                              case 'bottom-left':
                                element.style.left = `-${width - 2}px`
                                break
                            }
                          }
                        }}
                        className={`callout-text ${callout.position}`}>
                        {callout.text}
                      </motion.div>
                    </motion.div>
                )
              })
            }
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          {
            textBox &&
            <motion.div
              key={`text-box`}
              initial={{
                transform: `translateY(100%)`,
                opacity:0
              }}
              animate={{
                transform: `translateY(0%)`,
                opacity:1
              }}
              exit={{
                transform: `translateY(100%)`,
                opacity:0
              }}
              transition={{
                duration: 0.5
              }}
              style={{
                borderColor:timeline.colour
              }}
              className="text-box">
              {textBox.text}
            </motion.div>
          }
        </AnimatePresence>
        
        <AnimatePresence>
          {
            canvasLoading ?
              null :
            (scrollDownUI) ?
              <motion.div
                className="scroll-down"
                key={`scroll-down`}
                initial={{
                  opacity:0
                }}
                animate={{
                  opacity:1
                }}
                exit={{
                  opacity:0
                }}
                transition={{
                  duration: 0.5,
                  delay: 0.5
                }}
                >
                  scroll down
              </motion.div> :
              null
          }
        </AnimatePresence>
      </motion.div>
  )
}

export default AnimationLayer