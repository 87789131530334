import { useState, useEffect } from "react"
import { motion } from "framer-motion"

import AnimationLayer from "./AnimationLayer"
import FrameAnimation from "./FrameAnimation"

const variants = {
  open: {
    height: '100vh',
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2
    }
  },
  closed: {
    height: '95px',
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1
    }
  }
}

const SectionWrapper = (
  {
    db,
    currSection,
    dbStatus,
    innerWidth,
    innerHeight,
    viewportWidth,
    viewportHeight,
    yOffset,
    xOffset,
    totalFrames,
    currFrame,
    timeline,
    yPos,
    nextSection,
    previousSection,
    setXOffset,
    setYOffset,
    setCurrFrame,
    setSectionsClosed,
    canvasLoading
  }
) => {

  const [isSectionClosed, setIsSectionClosed] = useState(false)
  const [_canvasLoading, _setCanvasLoading] = useState(false)

  const toggleOpen = () => {
    console.log('toggle open', isSectionClosed)
    setIsSectionClosed(!isSectionClosed)
  }

  useEffect(() => {
    canvasLoading(_canvasLoading)
  }, [_canvasLoading])

  return(
    <motion.div
      key={`section-wrapper-${currSection}`}
      className="section-wrapper"
      variants={variants}
      initial="closed"
      animate={isSectionClosed ? "closed" : "open"}
      >
      <AnimationLayer
        canvasLoading={_canvasLoading}
        isClosed={isSectionClosed}
        toggleOpen={()=>setSectionsClosed()}
        innerWidth={innerWidth}
        innerHeight={innerHeight}
        viewportWidth={viewportWidth}
        viewportHeight={viewportHeight}
        yOffset={yOffset}
        xOffset={xOffset}
        totalFrames={totalFrames}
        currFrame={currFrame}
        timeline={timeline}
        yPos={yPos}
        nextSection={()=>nextSection()}
        previousSection={()=>setSectionsClosed()}/>
      {
        !isSectionClosed &&
        <FrameAnimation 
          db={db}
          dbStatus={dbStatus}
          innerWidth={innerWidth}
          innerHeight={innerHeight}
          viewportWidth={viewportWidth}
          viewportHeight={viewportHeight}
          setYOffset={(y) => setYOffset(y)}
          setXOffset={(x) => setXOffset(x)}
          timeline={timeline}
          section={currSection}
          yPos={yPos}
          closeSection={()=>setSectionsClosed()}
          setCurrFrame={(frame) => setCurrFrame(frame)}
          canvasLoading={(loading)=>_setCanvasLoading(loading)}/>
      }
    </motion.div>
  )
  
}

export default SectionWrapper