import BuildingElevation from '../assets/building_elevation.png'

import GCHT from '../assets/GCHT_logo.png'
import GCC from '../assets/GCC_council_logo.png'
import HES from '../assets/hes_logo.png'
import SUUM from '../assets/suum_logo.png'

const Credits = () => {

  return (
    <div className="credits-container">
      <img 
        className="building-elevation"
        src={BuildingElevation}/>
      <p className="title">This project was commissioned by GCHT for the people of Glasgow, designed by SUUM studio 2023.</p>
      <p
        style={{
          margin:0
        }}>
          What to do next?
      </p>
      <p
      style={{
        marginTop:0
      }}>Explore the development of Glasgow in the Victorian period, through the eyes of Thomas Sulman, illustrator of the Bird’s Eye View of the city from 1864.</p>
      <p>
        <a href="https://gallusglasgow.glasgowheritage.org.uk/" target="_blank">https://gallusglasgow.glasgowheritage.org.uk/</a>
      </p>
      <div className="logo-container">
        <a href="https://www.glasgowheritage.org.uk/" target="_blank">
          <img src={GCHT}/>
        </a>
        <a href="https://www.glasgow.gov.uk/" target="_blank">
          <img src={GCC}/>
        </a>
        <a href="https://www.historicenvironment.scot/" target="_blank">
          <img src={HES}/>
        </a>
        <a href="https://suum.studio/" target="_blank">
          <img src={SUUM}/>
        </a>
      </div>
      <p
        style={{
          fontSize:'1rem'
        }}>Glasgow City Heritage Trust is supported by Glasgow City Council and Historic Environment Scotland.</p>
      <p
      style={{
        fontSize:'1rem'
      }}>Company number SC318618  Scottish Charity Number SC038640</p>
    </div>
  )

}

export default Credits