import React, { useEffect, useRef, useState } from 'react'
import {
  AnimatePresence,
  motion
} from 'framer-motion'
import Loader from './Loader';

const FrameAnimation = ({
  timeline,
  section,
  yPos,
  setCurrFrame,
  setYOffset,
  setXOffset,
  innerWidth,
  innerHeight,
  viewportWidth,
  viewportHeight,
  db,
  dbStatus,
  closeSection,
  canvasLoading
}) => {
  const canvasRef = useRef(null)
  const [_frames, _setFrames] = useState([])
  const [_framesReady, _setFramesReady] = useState(false)

  const {
    frameCount
  } = timeline

  const currentFrame = index => (
    `https://d1qk8s4yjmvpvz.cloudfront.net/ourcitychambers/animations/${section}/${index.toString().padStart(4, '0')}.jpg`
  )

  useEffect(() => {
    //check frames ready
    console.log('caching images')
    cacheImagesWindow()
    canvasLoading(true)
  }, [])

  useEffect(() => {
    if(_framesReady) {
      const loadImg = async (index) => {
        /*let frame = _frames[index - 1]
        let url
        //check if image exists in database
        if(!frame) {
          //use url as image has been cached in window
          url = currentFrame(index)
        } else {
          //array buffer to blob
          const blob = new Blob([frame.buffer], { type: 'image/jpeg' })
          url = URL.createObjectURL(blob)
        */
  
        //check if image exists in window
  
  
  
        let url = currentFrame(index)
        const img = new Image()
        img.src = url
        img.onload = () => {
          setCurrFrame(index)
          coverImg(img, 'cover', url)
        }
      }
  
      loadImg(1)
  
      const canvas = canvasRef.current
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
  
      const updateImage = index => {
        //setCurrFrame(index)
        loadImg(index)
      }
  
      const handleScroll = () => {
  
        const scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop)
        const maxScrollTop = document.documentElement.scrollHeight - window.innerHeight
        const scrollFraction = scrollTop / maxScrollTop
        const frameIndex = Math.min(
          frameCount - 1,
          Math.ceil(scrollFraction * frameCount)
        )
        
        requestAnimationFrame(() => updateImage(frameIndex + 1))
      }
  
      window.addEventListener('scroll', handleScroll)
  
  
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [_framesReady])

  const [loadedFrameCount, setLoadedFrameCount] = useState(0)

  const setImageInWindow = (index) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = currentFrame(index)
      img.onload = () => {
        window[currentFrame(index)] = img
        setLoadedFrameCount(prevState => prevState + 1)
        resolve()
      }
      img.onerror = () => {
        reject()
      }
    })
  }


  const cacheImagesWindow = async() => {
    //promise all images
    const promises = []
    for (let i = 1; i < frameCount; i++) {
      promises.push(setImageInWindow(i))
    }
    Promise.all(promises)
      .then(() => {
        console.log(`All images cached in window for ${section}}`)
        //resolve async function
        _setFramesReady(true)
        canvasLoading(false)
      })
      .catch(() => {
        console.log('Error caching images in window')
      })
  }

  const preloadImages = () => {
    cacheImagesWindow()
    //create syncronous loop
    const preload = async () => {
      for (let i = 1; i <= frameCount; i++) {
        try {
          await fetchImage(i)
        } catch (error) {
          console.log(error)
        }
      }
    }
    preload()
  }

  const getAllFramesDB = async (keys) => {
    const transaction = await db.db.transaction([section], 'readonly')
    const store = await transaction.objectStore(section)
    const request = store.getAll()
    request.onerror = (event) => {
      console.log('Error loading image',request.error)
    }
    request.onsuccess = (event) => {
      console.log('All frames loaded',event.target.result)
      _setFramesReady(true)
      //set frames
      if(event.target.result.length === frameCount) {
        _setFrames(event.target.result)
      } else {
        //downloadStatus('downloading')
        preloadImages()
      }
    }
  }


  const fetchImage = async (id) => {
    //check if image exists in database
    const name = `${section}-${id.toString().padStart(4, '0')}`
    const image = await db.getImage(section,name)
    if(!image) {
      //fetch image as blob
      console.log(`Fetching image ${id}`)
      const response = await fetch(currentFrame(id))
      //save array buffer
      const arrayBuffer = await response.arrayBuffer()
      const data = {
        id: name,
        buffer: arrayBuffer
      }

      //add image to database
      await db.addImage(section,data)
      _setFrames([..._frames, data])
    } else {
      console.log(`Image ${id} already exists`)
      _setFrames([..._frames, image])
    }
    if(id === frameCount) {
      //downloadStatus('complete')
    }
  }

  const coverImg = (img, type, url) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d')
    const win = {
      w: viewportWidth,
      h: viewportHeight,
    }
    const imgRatio = img.height / img.width
    const winRatio = innerHeight / innerWidth
    if ((imgRatio < winRatio && type === 'contain') || (imgRatio > winRatio && type === 'cover')) {
      const h = innerWidth * imgRatio
      setYOffset((innerHeight - h) / 2)
      ctx.drawImage(img, 0, (innerHeight - h) / 2, innerWidth, h)
    }
    if ((imgRatio > winRatio && type === 'contain') || (imgRatio < winRatio && type === 'cover')) {
      const w = innerWidth * winRatio / imgRatio
      setXOffset((win.w - w) / 2)
      ctx.drawImage(img, (win.w - w) / 2, 0, w, innerHeight)
    }
    //URL.revokeObjectURL(url)
  }

  if(!_framesReady) {
    return (
      <div className="canvas-loading">
        <Loader />
        {`${(loadedFrameCount / frameCount * 100).toFixed(0)}%`}
      </div>
    )
  }

  return (
    <AnimatePresence>
      <motion.canvas 
        key={`canvas`}
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        ref={canvasRef}>
      </motion.canvas>
    </AnimatePresence>
  )
};

export default FrameAnimation