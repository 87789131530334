//create a class for IndexDB
class IndexDB {

  //initialise the class
  constructor() {
    this.db = null
  }

  //create the database
  async init(sections) {
    return new Promise((resolve, reject) => {  
      const request = indexedDB.open('ourcitychambers', 3)
      request.onerror = (event) => {
        if (this.error.name === "QuotaExceededError") {
          // Handle storage full error
          console.error("Quota exceeded! Can't save more data.");
        }    
        reject(false)
        console.log('Error opening database')
      }
      request.onsuccess = (event) => {
        this.db = event.target.result
        console.log('Database initialised')
        resolve(true)
      }
      request.onupgradeneeded = async (event) => {
        this.db = event.target.result
        for (let i = 0; i < sections.length; i++) {
          const store = await this.db.createObjectStore(sections[i], { keyPath: 'id' })
          await store.createIndex('id', 'id', { unique: true })
        }
        console.log('Database created')
        window.location.reload()
      }
    })
  }

  //bytes to megabytes
  bytesToMegabytes(bytes) {
    return bytes / 1000000
  }

  async checkStorage() {
    if (navigator.storage && navigator.storage.estimate) {
      const { usage, quota } = await navigator.storage.estimate();
      console.log(`Using ${this.bytesToMegabytes(usage)} out of ${this.bytesToMegabytes(quota)} MB.`)
    } else {
      console.log("Storage estimate API is not available in this browser.")
    }
  }

  //check storage usage



  //add an image to the database
  async addImage(_store,image) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([_store], 'readwrite')
      const store = transaction.objectStore(_store)
      const request = store.add(image)
      request.onerror = (event) => {
        console.log('Error adding image',event)
        reject(false)
      }
      request.onsuccess = (event) => {
        console.log('Image added')
        resolve(true)
      }
    })
  }

  //get an image from the database
  async getImage(_store,id) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([_store], 'readonly')
      const store = transaction.objectStore(_store)
      const request = store.get(id)
      request.onerror = (event) => {
        console.log('Error getting image')
        reject(false)
      }
      request.onsuccess = (event) => {
        console.log('Image retrieved')
        resolve(request.result)
      }
    })
  }

  //check if an image is in the database
  hasImage(_store,id) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([_store], 'readonly')
      const store = transaction.objectStore(_store)
      const request = store.get(id)
      request.onerror = (event) => {
        console.log('Error checking image')
        reject(false)
      }
      request.onsuccess = (event) => {
        console.log('Image exists')
        resolve(true)
      }
    })
  }
  
}

//export the class
export default new IndexDB()