import {
  useEffect,
  useState,
} from 'react'

import {
  motion,
  AnimatePresence,
} from 'framer-motion'

import {
  FaXmark
} from 'react-icons/fa6'

import OverlayModel from '../models/OverlayModel'
import ReactPlayer from 'react-player'

const Overlay = ({
  overlay,
  closeOverlay
}) => {

  const [overlayState, setOverlayState] = useState()

  useEffect(() => {
    setOverlayState(OverlayModel[overlay.overlay])
  }, [overlay])

  if (!overlayState) return null

  return(
    <AnimatePresence>
      <motion.h1 
        style={
          overlayState.headerBg ?
          {
          backgroundImage: `url(${overlayState.headerBg})`
          } : {
            backgroundColor: '#fff',
            color:'#000'
          }
        }
        className="overlay-header">
        {overlayState.header}
        <div 
          onClick={() => closeOverlay()}
          className="close-overlay">
          close
          <FaXmark
            className="overlay-close"/>
        </div>
      </motion.h1>
      <motion.div 
        key="overlay"
        className="overlay">
        {
          overlayState.video &&
          <ReactPlayer
            className="overlay-video"
            url={overlayState.video}
            playing={false}
            controls={true}
            width="100%"
            height="100%"/>
        }
        {
          overlayState.content &&
          <div
            className="overlay-content"
            dangerouslySetInnerHTML={{__html: overlayState.content}}/>
        }
        {
          overlayState.component &&
          <overlayState.component/>
        }
      </motion.div>
      
    </AnimatePresence>
  )

}

export default Overlay